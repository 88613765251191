<style lang="scss">

.client-sidebar {
    height: 100%;
    top: 7vh;
    z-index: 2;
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    font-size: 1vw;
    width: 5vw;
    overflow: hidden;
    transition: width 0.3s;
}
.new-sidebar__list {
  padding: 3vh 1vw;
  display: flex;
  cursor: pointer;
  border-left: 3px solid transparent;
  align-items: center;
  &:hover{
    border-left: 3px solid #ff7110;
    background-color: #fdf0e9;
  }
}
.new-sidebar__icon-color {
  color: #7c7c7c;
  font-size: 2em;
  padding: 0 0.7vw;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 4vw;
}
.menu-option-2{
    width: 0;
    transition: width 0.15s ease-out;
    overflow: hidden;
    position: absolute;
    margin-left: 5.5vw;
    color: #7c7c7c;
    background-color: #fdf0e9;
    border-radius: 7px;
    padding: 2vh 0;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -5px;
}
.menu-option-1{
    color: #7c7c7c;
    display: flex;
    align-items: center;
    padding: 0 1.5vw;
    border-radius: 0 7px 7px 0;
    text-transform: uppercase;
    margin-left: -1px;
    white-space: nowrap;
}
.active-class {color: var(--primary-color);}
.client-sidebar-arrow-container{
    position: absolute;
    background-color: white;
    margin-left: 5vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid #ff7110;
    }
}
.client-sidebar-arrow-container2{
    position: absolute;
    background-color: white;
    margin-left: 25vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid #ff7110;
    }
}
.sidebar-home-alert{
    font-weight: 400;
    font-size: 1em;
    background-color: var(--primary-color);
    color: #FFFFFF;
    border-radius: 5px;
    padding: 0.5vw;
    margin-left: 3vw;
}
.sidebar-options{
    position: absolute;
    margin-left: 20.1vw;
    margin-top: -13vh;
    background-color: white;
    border-radius: 8px;
    padding: 1vh 1vw;
    display: flex;
    flex-direction: column;
    width: max-content;
    min-width: 17vw;
    outline: 1px solid #ABABAB;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.sidebar-option{
    padding: 1vh 1vw;
    font-size: 1em;
    &:hover{background-color: #fdf0e9;}
}

@media only screen and (max-width: 630px) {
  .client-sidebar {
    display: none;
  }
}
</style>
<template>
    <div class="client-sidebar" :style="getExpandstyles()" @mouseover="showExpand()" @mouseleave="hideExpand()">
        <div class="hover-container" v-for="menu of permitted_menus" :key="menu.name" @mouseover="showMenuOptions(menu)" @mouseleave="hideMenuOptions(menu)">
            <RouterLink :to="menu.link"  class="new-sidebar__list">
                <span :class="{ 'active-class' : menu.active, active: menu.active}"  class="material-icons-outlined new-sidebar__icon-color">
                    {{ menu.icon }}
                </span> 
                <div class="menu-option-1" >
                    {{menu.name}}
                </div>
                <div v-if="menu.name == 'Home'" class="sidebar-home-alert">
                    Comece por Aqui
                </div>
                <span v-if="menu.options" class="material-icons-outlined" style="color:  var(--primary-color);">chevron_right</span>
                
            </RouterLink>
            <div class="sidebar-options" v-if="menu.showOptions && isExpanded" >
                <RouterLink :to="option.link" class="sidebar-option menu-option-1" v-for="option in menu.options" :key="option.name">
                    {{option.name}}
                </RouterLink>
            </div>
        </div>
        <div class="hover-container">
            <div v-on:click="logout()" class="new-sidebar__list">
                <span class="material-icons-outlined new-sidebar__icon-color">
                    logout
                </span> 
                <div v-if="isExpanded" class="menu-option-1" >
                    sair
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import * as CONSTANTS from "@/constants/constants";
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ perm_mixin ],
    data() {
        return {
            isExpanded: false,
            fullExpand: false,
            CONSTANTS: CONSTANTS,
            menus: [
                {
                    name: "Gerencial",
                    icon: " donut_large",
                    link: '/dashboard',
                    showOptions: false,
                    options: [
                        {
                            name: "Dashboard",
                            link: '/dashboard'
                        },
                        {
                            name: "Acompanhamento",
                            link: '/clientes/acompanhamentos',
                            resource: 'ATTENDANCE',
                        },
                        {
                            name: "Produtos Padrão",
                            resource: 'PRODUCT',
                            link: '/produtos/'
                        },
                    ]
                },
                {
                    name: "Entidades",
                    icon: " group ",
                    link: '/clientes',
                    showOptions: false,
                    options: [
                        {
                            name: "Clientes",
                            resource: 'CLIENT',
                            link: '/clientes/'
                        },
                        {
                            name: "Fornecedores",
                            resource: 'PROVIDER',
                            link: '/fornecedores/'
                        },
                        {
                            name: "Revendas",
                            resource: 'RESALE',
                            link: '/resales'
                        },
                    ]
                },
                {
                    name: "Operacional",
                    icon: " shopping_bag ",
                    link: '/pedidos',
                    showOptions: false,
                    options: [
                        {
                            name: "Venda Direta",
                            resource: 'DIRECT_PURCHASE',
                            link: '/pedidos'
                        },
                        {
                            name: "Convites",
                            resource: 'INVITATION',
                            link: '/convites/'
                        },
                        {
                            name: "Manutenção de Produtos",
                            resource: 'MAINTENANCE',
                            link: '/products/maintenance'
                        },
                        {
                            name: 'Painel de Operações',
                            resource: 'REQUEST',
                            link: CONSTANTS.ROUTE_MONITORS
                        },
                        {
                            name: 'Configurações de Campanha',
                            resource: 'SYSCONF',
                            link: '/settings'
                        }
                    ]
                },
                {
                    name: "Configurações da Conta",
                    icon: "manage_accounts",
                    resource: 'RESALE_SETTINGS',
                    action: 'READ',
                    link: '/revenda/configuracoes',
                    condition: () => {
                        return this.is_reseller
                    },
                    active: false,
                }
            ]
        };
    },
    computed: {
        permitted_menus: function() {
            const resp = []
            this.menus.forEach(element => {
                if (element.options){
                    const options = []
                    element.options.forEach(op => {if (this.can(op.resource, op.action || 'READ')) options.push(op)});
                    element.options = options
                    resp.push(element)
                } else if (element.resource == null || this.can(element.resource, element.action || 'READ')) resp.push(element)
            });
            return resp;
        }
    },
    methods: {
        hideMenuOptions(menu){
            if (menu.options) {
                menu.showOptions = false
                this.fullExpand = false
            }
        },
        showMenuOptions(menu){
            if (menu.options) {
                menu.showOptions = true
                this.fullExpand = true
            }
        },
        getExpandstyles() {
            if (this.fullExpand) return  'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); width: 25vw; overflow: visible'
            if (this.isExpanded) return 'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); width: 25vw'
        },
        showExpand() {
            this.isExpanded = true
        },
        hideExpand() {
            this.isExpanded = false
            this.menus.forEach(menu => menu.showOptions = false)
        },
        redirectOptions(option) {
            //this.isExpanded = false
            //this.menus[6].active = true
            //this.menus[6].showOptions = false
            this.$router.push(option.link)
        },
        redirect(menu){
            if (!menu.options) {
                this.menus.forEach(m => m.active = false)
                menu.active = true;
                this.$forceUpdate()
                this.isExpanded = false
                // this.$router.push(menu.link)
            }
        },
        logout() {
            this.$store.dispatch('user/logout')
            location.href = CONSTANTS.ROUTE_LOGIN;
        },
        activate(menu) {
            this.menus.forEach(m => m.active = false)
            menu.active = true;
            this.$forceUpdate()
        },
    }
}
</script>
